<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#9b221f"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#9b221f" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Settings </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12 py-2 v-if="$route.query.u == 'Terms'">
            <v-layout wrap>
              <v-flex xs12 md12 text-left>
                <span class="textField1">Terms and Conditions</span>
                <div id="app">
                  <vue-editor v-model="termsAndConditions"></vue-editor>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md12 py-2 v-if="$route.query.u == 'Privacy'">
            <v-layout wrap>
              <v-flex xs12 md12 text-left>
                <span class="textField1">Privacy Policy</span>
                <div id="app">
                  <vue-editor v-model="privacyPolicy"></vue-editor>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md12 py-2 v-if="$route.query.u == 'Cancel'">
            <v-layout wrap>
              <v-flex xs12 md12 text-left>
                <span class="textField1">Cancellation Policy</span>
                <div id="app">
                  <vue-editor v-model="cancellationPolicy"></vue-editor>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md12 py-2 v-if="$route.query.u == 'Contact'">
            <v-layout wrap>
              <v-flex xs12 md12 text-left>
                <span class="textField1">Contact Us</span>
              </v-flex>
              <v-flex xs12 md12 py-2 text-left>
                <span class="textField1">Email</span>
                <v-text-field
                  v-model="contactUs.email"
                  label="Email"
                  :rules="emailRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md12 py-2 text-left>
                <span class="textField1">Phone 1</span>
                <v-text-field
                  v-model="contactUs.phone1"
                  :rules="phnoRules"
                  required
                  outlined
                  @wheel="$event.target.blur()"
                  type="number"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 py-2 text-left>
                <span class="textField1">Phone 2</span>
                <v-text-field
                  v-model="contactUs.phone2"
                  :rules="phnoRules2"
                  required
                  outlined
                  @wheel="$event.target.blur()"
                  type="number"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="#9b221f"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      contactUs: {
        email: "",
        phone1: "",
        phone2: "",
      },
      termsAndConditions: null,
      privacyPolicy: null,
      cancellationPolicy: null,
      valid: false,
      formData: new FormData(),

      formDataCover: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
      phnoRules2: [
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/settings/get",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.data.termsAndConditions) {
            this.termsAndConditions = response.data.data.termsAndConditions;
          }

          if (response.data.data.privacyPolicy) {
            this.privacyPolicy = response.data.data.privacyPolicy;
          }

          if (response.data.data.cancellationPolicy) {
            this.cancellationPolicy = response.data.data.cancellationPolicy;
          }
          if (response.data.data.contactUs) {
            if (response.data.data.contactUs.email) {
              this.contactUs.email = response.data.data.contactUs.email;
            }
            if (response.data.data.contactUs.phone1) {
              this.contactUs.phone1 = response.data.data.contactUs.phone1;
            }
            if (response.data.data.contactUs.phone2) {
              this.contactUs.phone2 = response.data.data.contactUs.phone2;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput() {
      // if (!this.product.name) {
      //   this.msg = "Please Provide Product name";
      //   this.showSnackBar = true;
      //   return;
      // } else {
      this.updatePolicy();
      // }
    },

    updatePolicy() {
      var data = {};
      if (this.$route.query.u == "Terms")
        data["termsAndConditions"] = this.termsAndConditions;

      if (this.$route.query.u == "Privacy")
        data["privacyPolicy"] = this.privacyPolicy;
      if (this.$route.query.u == "Cancel")
        data["cancellationPolicy"] = this.cancellationPolicy;

      if (this.$route.query.u == "Contact") data["contactUs"] = this.contactUs;
      axios({
        method: "post",
        url: "/settings/set",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;

            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>
